import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout } from 'layouts';
import { PostBlock } from 'components';

const Index =  ({ data }) => {
    const { edges } = data.allMarkdownRemark;
    return (
    <Layout>
        {edges.map(({ node }) => (
            <PostBlock
                title={node.frontmatter.title}
                author={node.frontmatter.author} 
                date={node.frontmatter.date}
                cover={false}
                excerpt={node.excerpt}
                slug={node.fields.slug}
                id={node.id}
                tags={node.frontmatter.tags}
            />
        ))}
    </Layout>
    )
};

export default Index;

Index.propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              fields: PropTypes.shape({
                  slug: PropTypes.string.isRequired,
              }),
              id: PropTypes.string.isRequired,
              excerpt: PropTypes.string,
              frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                author: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
                tags: PropTypes.array,
              }),
            }),
          }).isRequired
        ),
      }),
    }),
  };

export const query = graphql`
    query PostsForIndex {
        allMarkdownRemark (
            filter: { fileAbsolutePath: {regex: "/posts/"}, frontmatter: { published: { eq: true } } },
            sort: { order: DESC, fields: [frontmatter___date] },
            limit: 20,
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    excerpt(pruneLength:150)
                    frontmatter {
                        title
                        author
                        date(formatString: "MMMM DD, YYYY")
                        tags
                    }
                }
            }
        }
    }
`;